import axios from 'axios';
//toast
import { toast } from 'react-toastify';

const createNewBaseUrl = url =>
  axios.create({
    baseURL: url,
  });

const apiService = createNewBaseUrl(process.env.REACT_APP_API_URL);
const apiKongService = createNewBaseUrl(process.env.REACT_APP_KONG_LOYALTY_SERVICE_URL);

const requestInterceptor = config => {
  config.headers['device'] = 2;
  return config;
};

const requestInterceptorError = error => {
  // eslint-disable-next-line no-console
  console.log('there is a request error', error);
  return Promise.reject(error);
};

const responseInterceptor = response => {
  return response;
};

const responseInterceptorError = error => {
  const errorResponse = error.response;
  // eslint-disable-next-line no-console
  console.log('response interceptor error:', errorResponse);

  if (errorResponse?.data?.Error?.Message) {
    toast.error(errorResponse.data.Error.Message);
  }
  if (errorResponse?.data?.message) {
    toast.error(errorResponse.data.message);
  }
  if (errorResponse?.data?.Message) {
    toast.error(errorResponse.data.Message);
  }
  if (errorResponse?.data?.title) {
    toast.error(errorResponse.data.title);
  }
  return Promise.reject(error);
};

const addInterceptors = service => {
  service.interceptors.request.use(requestInterceptor, requestInterceptorError);
  service.interceptors.response.use(responseInterceptor, responseInterceptorError);
};

addInterceptors(apiService);
addInterceptors(apiKongService);

export { apiService, apiKongService };
