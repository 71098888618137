import React from 'react';
import {ReactComponent as EarnPointsIcon} from '../../assets/images/earnPointsIcon2.svg';
import {Link} from "react-router-dom";
import { useSelector } from 'react-redux';
//selectors
import { getLangJson } from '../../store/selectors/LangSelector';

function EarnPoints() {
    const t = useSelector((state) => getLangJson({ state }));
    return(
        <div className="earnpoints-div pink-bg">
            <div className="earnpoints-content">
                <p className="earnpoints-title sz-24 medium-txt">{t.earnpoints.title}</p>
                <Link className="earnpoints-link sz-16 medium-txt" to="/earn-points">{t.earnpoints.link}</Link>
                <EarnPointsIcon className="earnpoints-icon"/>
            </div>
        </div>
    );
}

export default EarnPoints;