//services
import { apiKongService } from './HttpService';
//end points
import {
  initSessionEndPoint,
  getTokenEndPoint,
  getPointsEndPoint,
  redeemPointsEndPoint,
  getCurrencyEndPoint,
  getStatementsEndPoint,
} from './EndPoints';

class PointsService {
  static initSession(data) {
    return apiKongService({
      method: 'POST',
      url: initSessionEndPoint(),
      data,
    });
  }
  static getToken(data) {
    return apiKongService({
      method: 'GET',
      url: getTokenEndPoint(data),
    });
  }
  static getPoints(authorization, params) {
    return apiKongService({
      method: 'GET',
      url: getPointsEndPoint(),
      headers: {
        Authorization: authorization,
      },
      params,
    });
  }
  static redeemPoints(authorization, data) {
    return apiKongService({
      method: 'PUT',
      url: redeemPointsEndPoint(),
      headers: {
        Authorization: authorization,
      },
      data,
    });
  }
  static getCurrency(data) {
    return apiKongService({
      method: 'GET',
      url: getCurrencyEndPoint(data),
    });
  }
  static getStatements(authorization) {
    return apiKongService({
      method: 'GET',
      url: getStatementsEndPoint(),
      headers: {
        Authorization: authorization,
      },
    });
  }
}

export default PointsService;
