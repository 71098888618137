import React from 'react';
import { ReactComponent as BalanceIcon } from '../../assets/images/balanceIcon2.svg';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
//selectors
import { getLangJson, getLang } from '../../store/selectors/LangSelector';

function Balance({ points, currency }) {
    const t = useSelector((state) => getLangJson({ state })),
        lang = useSelector((state) => getLang({ state }));

    return (
        <div className="balance-div white-bg">
            <div className="balance-content">
                <p className="balance-title sz-16 medium-txt">{t.balance.title}</p>
                <p className="balance-points sz-16 bold-txt">{points.currentPoints ?? 0} {t.balance.points}</p>
                <p className="balance-points sz-16 bold-txt">=</p>
                <p className="balance-points sz-16 bold-txt">{points.redeemableBalance ?? 0} {currency && lang === "ar" ? currency?.nameAr : currency?.nameEn}</p>
                <p className="balance-txt sz-12 medium-txt">{t.balance.desc}</p>
                <BalanceIcon className="balance-icon" />
            </div>
        </div>
    );
}

Balance.propTypes = {
    points: PropTypes.object.isRequired,
    currency: PropTypes.object.isRequired
};

export default Balance;