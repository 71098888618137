import React, { useEffect } from 'react';
import {ReactComponent as Points} from '../assets/images/points2.svg';
import {ReactComponent as Delivery} from '../assets/images/delivery.svg';
import {ReactComponent as Vat} from '../assets/images/vat.svg';
import {ReactComponent as Note} from '../assets/images/note.svg';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
//selectors
import { getLangJson, getLang } from '../store/selectors/LangSelector';
import { getCurrencyData } from '../store/selectors/PointsSelector';
//constants
import { getQueryParams } from '../constants/Helpers';
//actions
import { setCurrency } from '../store/actions/PointsActions';
import { setLang } from '../store/actions/LangActions';
import PropTypes from 'prop-types';

function EarnPoints({ location }) {
    const t = useSelector((state) => getLangJson({ state })),
        currency = useSelector((state) => getCurrencyData({ state })),
        lang = useSelector((state) => getLang({ state })),
		queryParams = getQueryParams(location.search),
        history = useHistory(),
        dispatch = useDispatch();

    useEffect(() => {
        if (queryParams.currency && queryParams.rate && queryParams.points) {
            let curr = {
                exchangeRate: queryParams.rate,
                nameAr: queryParams.currency,
                nameEn: queryParams.currency,
                points: queryParams.points,
            };
			dispatch(setCurrency(curr));
            if (queryParams.locale){
                const lang = queryParams.locale.indexOf("en");
                if(lang > -1){
                    dispatch(setLang("en"));
                }else{
                    dispatch(setLang("ar"));
                }
            }
		}else{
            if (!currency?.points) {
                history.push("/session");
            }
        }
    },[]);

    return(
        <div className="main-container earnpoints-page">
            <div className="blue-header">
                <h1 className="pointspage-title">{t.earnpoints.title}</h1>
            </div>
            <div className="pointspage-content">
                <div className="point-section">
                    <Points className="pointspage-icon"/>
                    <p className="point-title sz-16 medium-txt">{t.earnpoints.points.title(currency? currency?.exchangeRate : "", currency? lang === "ar"? currency?.nameAr : currency?.nameEn : "")}</p>
                    <p className="point-txt sz-16 light-txt">{t.earnpoints.points.desc(currency? currency?.points : "", currency? lang === "ar"? currency?.nameAr : currency?.nameEn : "")}</p>
                </div>
                <div className="point-section">
                    <Delivery className="pointspage-icon"/>
                    <p className="point-title sz-16 medium-txt">{t.earnpoints.delivery.title}</p>
                    <p className="point-txt sz-16 light-txt">{t.earnpoints.delivery.desc}</p>
                </div>
                <div className="point-section">
                    <Vat className="pointspage-icon"/>
                    <p className="point-title sz-16 medium-txt">{t.earnpoints.vat.title}</p>
                </div>
                <div className="point-section">
                    <Note className="pointspage-icon"/>
                    <p className="point-title sz-16 medium-txt">{t.earnpoints.note.title}</p>
                    <p className="point-txt sz-16 light-txt">{t.earnpoints.note.desc}</p>
                    <p className="point-txt sz-16 light-txt">{t.earnpoints.note.note}</p>
                </div>
            </div>
        </div>
    );
}
EarnPoints.propTypes = {
    location: PropTypes.object.isRequired
};
export default EarnPoints;