export const initSessionEndPoint = () => 'session/init';

export const getTokenEndPoint = token => `session/${token}`;

export const getPointsEndPoint = () => `points/banner`;

export const redeemPointsEndPoint = () => `redemption`;

export const getCurrencyEndPoint = currency => `currencies/${currency}`;

export const getStatementsEndPoint = () => `points/statements`;
